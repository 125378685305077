import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import config from './okta/config';
import AppRoutes from './components/Routes';
import { semanticKernelLightTheme } from './styles';
import { FluentProvider /*Subtitle1*/ } from '@fluentui/react-components';
import { AbortControllerProvider } from './AbortControllerProvider';

const oktaAuth = new OktaAuth(config.oidc);

const useRestoreOriginalUri = () => {
    const navigate = useNavigate();

    return (_oktaAuth: OktaAuth, originalUri: string | undefined) => {
        void navigate(toRelativeUrl(originalUri ?? '/', window.location.origin));
    };
};

const App: React.FC = () => {
    const restoreOriginalUri = useRestoreOriginalUri();

    return (
        <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <AbortControllerProvider>
                    <AppRoutes />
                </AbortControllerProvider>
            </Security>
        </FluentProvider>
    );
};

export default App;
