import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { Breakpoints, SharedStyles } from '../../styles';
import { ImagesInput } from './ImagesInput';
import { ImagesHistory } from './images-history/ImagesHistory';
import { useImages } from '../../libs/hooks/useImages';
import { IImagesPromptData } from '../../libs/services/ImagesService';

const useClasses = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        overflowY: 'auto',
    },
    scroll: {
        ...shorthands.margin(0),
        ...SharedStyles.scroll,
        position: 'relative',
    },
    history: {
        ...shorthands.padding(tokens.spacingVerticalM),
        marginLeft: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        display: 'flex',
        justifyContent: 'center',
        ...Breakpoints.small({
            marginLeft: '15px',
            paddingRight: '15px',
        }),
    },
    input: {
        ...shorthands.padding(tokens.spacingVerticalM),
    },
    infobar: {
        backgroundColor: '#E0E5F0',
        gridTemplateAreas: 'body actions',
        ...shorthands.borderRadius('0'),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXXL),
        ...shorthands.border('1px', 'solid', '#54616D'),
        minHeight: 'unset',
        fontSize: '16px',
        '@media (max-width: 1440px)': {
            ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXXXL),
            minHeight: 'unset',
        },
        '& .fui-MessageBar__icon': {
            display: 'none',
        },
    },
});

export const ImagesRoom: React.FC = () => {
    const classes = useClasses();
    const images = useImages();

    const { conversation } = useAppSelector((state: RootState) => state.images);
    const messages = conversation.messages;

    const scrollViewTargetRef = React.useRef<HTMLDivElement>(null);
    const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

    // const [showBanner, setShowBanner] = React.useState(false);

    React.useEffect(() => {
        if (!shouldAutoScroll) return;

        setTimeout(() => {
            scrollViewTargetRef.current?.scrollTo(0, scrollViewTargetRef.current.scrollHeight);
        }, 1000);
    }, [conversation, shouldAutoScroll]);

    React.useEffect(() => {
        const onScroll = () => {
            if (!scrollViewTargetRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } = scrollViewTargetRef.current;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
            setShouldAutoScroll(isAtBottom);
        };

        if (!scrollViewTargetRef.current) return;

        const currentScrollViewTarget = scrollViewTargetRef.current;

        currentScrollViewTarget.addEventListener('scroll', onScroll);

        // if (!localStorage.getItem('shownBanner')) {
        //     setShowBanner(true);
        // }

        return () => {
            currentScrollViewTarget.removeEventListener('scroll', onScroll);
        };
    }, []);

    const handleSubmit = async (options: IImagesPromptData) => {
        await images.createImageAsync(options);
        setShouldAutoScroll(true);
    };
    // const hideBanner = () => {
    //     setShowBanner(false);
    //     localStorage.setItem('shownBanner', 'true');
    // };

    // if (conversations[selectedId].hidden) {
    //     return (
    //         <div className={classes.root}>
    //             <div className={classes.scroll}>
    //                 <div className={classes.history}>
    //                     <h3>
    //                         This conversation is not visible in the app because Live Chat Session Sharing is disabled.
    //                         Please enable the feature in the settings to view the conversation, select a different one,
    //                         or create a new conversation.
    //                     </h3>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
    //
    // if (!conversations[selectedId].messagesLoaded) {
    //     return <Loading text="Loading chat history..." />;
    // }

    return (
        <div className={classes.root}>
            {/*{showBanner && (*/}
            {/*    <MessageBar className={classes.infobar} layout={'multiline'}>*/}
            {/*        <MessageBarBody>*/}
            {/*            We are now using Chat GPT 4 Turbo as a model meaning the knowledge of MaIA is now extended to*/}
            {/*            April 2023 vs Sept 2021.*/}
            {/*        </MessageBarBody>*/}
            {/*        <MessageBarActions*/}
            {/*            containerAction={*/}
            {/*                <Button*/}
            {/*                    aria-label="dismiss"*/}
            {/*                    appearance="transparent"*/}
            {/*                    icon={<DismissRegular />}*/}
            {/*                    onClick={hideBanner}*/}
            {/*                />*/}
            {/*            }*/}
            {/*        ></MessageBarActions>*/}
            {/*    </MessageBar>*/}
            {/*)}*/}
            <div ref={scrollViewTargetRef} className={classes.scroll}>
                <div className={classes.history}>
                    <ImagesHistory messages={messages} />
                </div>
                <div className="fade" id="fade"></div>
            </div>
            <div className={classes.input}>
                <ImagesInput onSubmit={handleSubmit} />
            </div>
        </div>
    );
};
