import React from 'react';
import { addAlert } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import { useAppDispatch } from '../../../redux/app/hooks';
import CopyLink from '../../../assets/images/CopyLink.svg';
import { Image } from '@fluentui/react-components';
import { useMediaQuery } from '@mui/material';

interface CopyLinkProps {
    content: { contentHtml: string; contentText: string };
}

const CopyLinkIcon = () => {
    return <Image src={CopyLink} />;
};

const CopyLinkComponent: React.FC<CopyLinkProps> = ({ content }) => {
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery('(max-width: 744px)');

    const copyHtmlToClipboard = async (text: { contentHtml: string; contentText: string }) => {
        try {
            if (navigator.clipboard.write && window.ClipboardItem) {
                const clipboardItem = new ClipboardItem({
                    'text/html': new Blob([text.contentHtml], { type: 'text/html' }),
                    'text/plain': new Blob([text.contentText], { type: 'text/plain' }),
                });

                await navigator.clipboard.write([clipboardItem]);

                dispatch(addAlert({ message: 'HTML copied to clipboard', type: AlertType.Info }));
            } else {
                await navigator.clipboard.writeText(text.contentText);
                dispatch(addAlert({ message: 'HTML copied as plain text', type: AlertType.Info }));
            }
        } catch (error) {
            dispatch(addAlert({ message: `Error copying content: ${String(error)}`, type: AlertType.Error }));
        }
    };

    return (
        <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#576DDD' }}
            onClick={() => void copyHtmlToClipboard(content)}
        >
            <div className="svg-container" style={{ display: 'flex', gap: isMobile ? '' : '8px' }}>
                <CopyLinkIcon />
                <span>COPY</span>
            </div>
        </div>
    );
};

export default CopyLinkComponent;
