import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ChatService } from '../../../libs/services/ChatService';
import { useOktaAuth } from '@okta/okta-react';
import { Button, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { Breakpoints } from '../../../styles';
import { updateMessageSatisfied } from '../../../redux/features/conversations/conversationsSlice';
import { styled } from '@mui/material/styles';
import { TextField, useMediaQuery } from '@mui/material';
import { Fade } from '@mui/material';

interface FeedbackProps {
    messageUuid: string;
    feedback: boolean | null;
    setFeedback: (satisfied: boolean | null) => void;
}

const useClasses = makeStyles({
    textarea: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        position: 'relative',
        right: '0',
        bottom: '10px',
        zIndex: '100',
        '& button': {
            position: 'absolute',
            right: '0',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '1',
        },
    },
    btnSelect: {
        ...shorthands.borderRadius('20px'),
        ...shorthands.border('1px', 'solid', '#9CB0EC'),
        ...shorthands.padding('4px', '16px'),
        maxWidth: '163px',
        color: '#576DDD',
        fontWeight: '400',
        ...shorthands.gap('5px'),
        marginRight: '5px',
        marginBottom: '5px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    },
    selected: {
        ...shorthands.borderColor('#576DDD'),
        color: '#fff',
        backgroundColor: '#576DDD!important',
    },
    reviewParent: {
        display: 'flex',
        flexWrap: 'wrap',
        ...Breakpoints.small({
            flexDirection: 'column',
        }),
        '& label:hover': {
            backgroundColor: 'rgb(101, 107, 128,.3)',
        },
    },
    textField: {
        maxHeight: '230px',
        ...shorthands.padding('12px', '170px', '12px', '12px'),
        ...shorthands.borderRadius('4px', '4px', '4px', '4px'),
        ...shorthands.border('1px', 'solid', '#1A1D20'),
        '&:focus': {
            ...shorthands.borderColor('#9CB0EC'),
            ...shorthands.outline('0'),
        },
    },
    btn: {
        backgroundColor: '#576DDD',
        ...shorthands.border('0'),
        ...shorthands.borderRadius('50%'),
        maxWidth: '32px',
        minWidth: '32px',
        width: '32px',
        maxHeight: '32px',
        minHeight: '32px',
        height: '32px',
        marginRight: '7px',
        '&:hover': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
            '&:active': {
                backgroundColor: '#576DDD',
                opacity: '0.7',
            },
        },
        '&:active': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
        },
    },
});

const SendIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.14645 3.5607L2 8.70714L1.29289 8.00004L7.64645 1.64648L14 8.00004L13.2929 8.70714L8.14645 3.5607L8.14645 14.3536L7.14645 14.3536L7.14645 3.5607Z"
            fill="white"
        />
    </svg>
);

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6465 13.3536L2.64648 3.35359L3.35359 2.64648L13.3536 12.6465L12.6465 13.3536Z"
            fill="#656B80"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3536 3.35352L3.35359 13.3535L2.64648 12.6464L12.6465 2.64641L13.3536 3.35352Z"
            fill="#656B80"
        />
    </svg>
);

const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6666 7.99967C13.6666 11.1293 11.1295 13.6663 7.99992 13.6663C4.87031 13.6663 2.33325 11.1293 2.33325 7.99967C2.33325 4.87006 4.87031 2.33301 7.99992 2.33301C11.1295 2.33301 13.6666 4.87006 13.6666 7.99967ZM14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967ZM8.25006 5.03293C8.25006 5.35693 8.51106 5.62693 8.84406 5.62693C9.16806 5.62693 9.44706 5.35693 9.44706 5.03293C9.44706 4.69993 9.16806 4.42993 8.84406 4.42993C8.51106 4.42993 8.25006 4.69993 8.25006 5.03293ZM6.52206 8.17393C7.16106 7.35493 7.43106 7.37293 7.60206 7.44493L6.35106 10.4059C6.12606 10.9279 6.20706 11.0899 6.57606 11.0899C7.22406 11.0899 8.28606 10.5049 8.82606 9.62293L8.78106 9.56893C8.34006 10.0099 7.95306 10.1719 7.59306 10.2799L8.65506 7.77793C8.99706 6.96793 8.67306 6.69793 8.30406 6.69793C7.72806 6.69793 7.04406 7.12093 6.45006 8.11993L6.52206 8.17393Z"
            fill="#5564DA"
        />
    </svg>
);

const CssTextField = styled(TextField)({
    width: '100%',
    '& label': {
        color: '#54616D',
        top: '-3px',
        '@media (max-width: 744px)': {
            fontSize: '15px',
        },
    },
    '& label.Mui-focused': {
        color: '#1D1D1D',
        top: '0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#8F8A80',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '24px',
        background: '#fff',
        '& fieldset': {
            borderColor: '#8F8A80',
        },
        '&:hover fieldset': {
            borderColor: '#8F8A80',
        },
        '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: '#8F8A80',
        },
    },
    '& .MuiInputBase-input': {
        paddingRight: '40px',
        paddingTop: '12.5px',
        paddingBottom: '12.5px',
        paddingLeft: '14px',
    },
});

const Feedback: React.FC<FeedbackProps> = ({ messageUuid, feedback, setFeedback }) => {
    const classes = useClasses();
    const [reasons, setReasons] = useState<string[]>([]);
    const [comments, setComments] = useState<string>('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const chatService = new ChatService();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { oktaAuth } = useOktaAuth();
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery('(max-width: 744px)');

    const feedbackRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (feedback !== null && feedbackRef.current) {
            feedbackRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [feedback]);

    useEffect(() => {
        setReasons([]);
        setComments('');
    }, [feedback]);

    useEffect(() => {
        if (feedbackSubmitted) {
            setShowThankYou(true);

            const timer = setTimeout(() => {
                setShowThankYou(false);
                setFeedbackSubmitted(false);
                setFeedback(null);
                setReasons([]);
                setComments('');
            }, 4000);

            return () => {
                clearTimeout(timer);
            };
        }

        return undefined;
    }, [feedbackSubmitted]);

    const handleReasonChange = (reason: string) => {
        setReasons((prevReasons) =>
            prevReasons.includes(reason) ? prevReasons.filter((r) => r !== reason) : [...prevReasons, reason],
        );
    };

    const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setComments(event.target.value);
    };

    const handleSubmit = () => {
        const requestBody = {
            conversation_uuid: selectedId,
            message_uuid: messageUuid,
            satisfied: feedback,
            reasons: reasons,
            comments: comments,
        };
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';
        void chatService.createFeedback(requestBody, accessToken, idToken);

        dispatch(updateMessageSatisfied({ satisfied: Boolean(feedback), chatId: selectedId, uuid: messageUuid }));

        setFeedbackSubmitted(true);
        setFeedback(null);
        setReasons([]);
        setComments('');
    };

    const positiveReasons = ["That's exactly what I asked for", 'I love the style', 'Others'];
    const negativeReasons = ['That’s not what I asked', 'Incorrect answer', 'Not the expected style', 'Others'];

    if (feedback === null && !feedbackSubmitted) {
        return null;
    }

    return (
        <div ref={feedbackRef} style={{ marginTop: '10px', position: 'relative' }}>
            <Fade in={showThankYou} timeout={500}>
                <div
                    style={{ position: 'absolute', right: '10px', top: '15px', fontSize: '24px', cursor: 'pointer' }}
                    onClick={() => {
                        setFeedbackSubmitted(false);
                        setFeedback(null);
                        setReasons([]);
                        setComments('');
                    }}
                >
                    <CloseIcon />
                </div>
            </Fade>
            {!showThankYou && (
                <div
                    style={{ position: 'absolute', right: '10px', top: '15px', fontSize: '24px', cursor: 'pointer' }}
                    onClick={() => {
                        setFeedbackSubmitted(false);
                        setFeedback(null);
                        setReasons([]);
                        setComments('');
                    }}
                >
                    <CloseIcon />
                </div>
            )}
            {!feedbackSubmitted ? (
                <div
                    style={{
                        border: '1px solid #9AA0A0',
                        borderRadius: '5px',
                        padding: '15px',
                        fontSize: '12px',
                    }}
                >
                    {feedback !== null && (
                        <>
                            <div>
                                <p style={{ color: '#54616D', fontSize: '14px', marginTop: '0' }}>
                                    Why did you choose this evaluation?
                                </p>
                                <div className={classes.reviewParent}>
                                    {feedback
                                        ? positiveReasons.map((reason, index) => (
                                              <label
                                                  key={reason}
                                                  htmlFor={reason}
                                                  style={
                                                      index === 0
                                                          ? { backgroundColor: 'rgb(101, 107, 128, .3)' }
                                                          : undefined
                                                  }
                                                  className={
                                                      reasons.includes(reason)
                                                          ? mergeClasses(classes.btnSelect, classes.selected)
                                                          : classes.btnSelect
                                                  }
                                              >
                                                  <input
                                                      style={{ display: 'none' }}
                                                      type="checkbox"
                                                      id={reason}
                                                      value={reason}
                                                      checked={reasons.includes(reason)}
                                                      onChange={() => {
                                                          handleReasonChange(reason);
                                                      }}
                                                  />
                                                  {reason}
                                              </label>
                                          ))
                                        : negativeReasons.map((reason) => (
                                              <label
                                                  key={reason}
                                                  htmlFor={reason}
                                                  className={
                                                      reasons.includes(reason)
                                                          ? mergeClasses(classes.btnSelect, classes.selected)
                                                          : classes.btnSelect
                                                  }
                                              >
                                                  <input
                                                      style={{ display: 'none' }}
                                                      type="checkbox"
                                                      id={reason}
                                                      value={reason}
                                                      checked={reasons.includes(reason)}
                                                      onChange={() => {
                                                          handleReasonChange(reason);
                                                      }}
                                                  />
                                                  {reason}
                                              </label>
                                          ))}
                                </div>
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <div className={classes.textarea}>
                                    <CssTextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label={`Share other comments ${isMobile ? '' : '(Optional)'}`}
                                        value={comments}
                                        onChange={handleCommentChange}
                                    />
                                    <Button
                                        title="Submit"
                                        aria-label="Submit message"
                                        appearance="transparent"
                                        className={classes.btn}
                                        icon={<SendIcon />}
                                        disabled={reasons.length === 0}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <Fade in={showThankYou} timeout={500}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            border: '1px solid #5564DA',
                            padding: '15px 30px 15px 15px',
                            gap: '8px',
                        }}
                    >
                        <InfoIcon /> <span>Thank you for your comments!</span>
                    </div>
                </Fade>
            )}
        </div>
    );
};

export default Feedback;
