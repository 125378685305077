import { FC } from 'react';
import { Button, makeStyles, shorthands, mergeClasses } from '@fluentui/react-components';
import { Breakpoints } from '../../../styles';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setIsOpenMobileMenu, setSelectedTab } from '../../../redux/features/app/appSlice';

const BookmarkSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.833008 0H10.1663V12.1876L5.49967 10.0338L0.833008 12.1876V0ZM1.49967 0.666667V11.1457L5.49967 9.29954L9.49967 11.1457V0.666667H1.49967Z"
                fill="#576DDD"
            />
        </svg>
    );
};

const useClasses = makeStyles({
    button: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '15px',
        ...Breakpoints.small({
            marginBottom: '15px',
            marginTop: '15px',
        }),
    },
    btn: {
        ...shorthands.borderRadius('20px'),
        ...shorthands.borderColor('#9CB0EC'),
        ...shorthands.padding('4px', '16px'),
        width: '100%',
        maxWidth: '210px',
        color: '#576DDD',
        fontWeight: '400',
        '&:hover': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
        '&:hover:active': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
        },
        '&[data-fui-focus-visible]': {
            ...shorthands.borderRadius('20px'),
            ...shorthands.borderColor('#9CB0EC'),
        },
        '& span': {
            marginLeft: '5px',
        },
    },
    btnActive: {
        ...shorthands.borderColor('#2035A2'),
        color: '#fff',
        backgroundColor: '#2035A2',
        '& svg': {
            stroke: '#fff',
        },
    },
});

export const PromptLibraryButton: FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { isOpenMobileMenu, selectedTab } = useAppSelector((state: RootState) => state.app);
    const buttonClasses = mergeClasses(
        classes.btn,
        'gtmPromptStudioAccess',
        selectedTab === 'promptLibrary' && classes.btnActive,
    );

    const onClick = () => {
        dispatch(setSelectedTab('promptLibrary'));
        dispatch(setIsOpenMobileMenu(!isOpenMobileMenu));
    };

    return (
        <div className={mergeClasses(classes.button, 'gtmPromptStudioAccess')}>
            <Button className={buttonClasses} size="large" onClick={onClick} appearance="secondary">
                <BookmarkSvg /> <span>PROMPT STUDIO</span>
            </Button>
        </div>
    );
};
