import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setModelName } from '../../../redux/features/conversations/conversationsSlice';
import { MODEL_NAME } from '../../../redux/features/conversations/ConversationsState';
import { Menu, MenuList, MenuItem, MenuPopover, MenuTrigger, makeStyles } from '@fluentui/react-components';
// import { Checkmark20 } from '../../shared/BundledIcons';
import { modelLabels, modelNames, modelSubLabels } from '../../../Constants';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles({
    dropdownContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    dropdownItem: {
        width: '100%',
        position: 'relative',
        paddingBottom: '8px',
        '&:not(:last-of-type):after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: '0',
            width: '100%',
            height: '1px',
            backgroundColor: '#E0E5F0',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:hover:active': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
    },
    dropdownItemLabel: {
        fontSize: '16px',
        fontWeight: 700,
    },
    dropdownItemSubLabel: {
        fontSize: '14px',
        fontWeight: 300,
    },
});

const ChatChaneIcon = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 256 256">
        <g>
            <g>
                <g>
                    <path
                        fill="currentColor"
                        d="M116.8,10.6c-22.6,2.2-45.9,11.8-63.2,25.9c-6.7,5.5-16.9,16.4-22,23.4c-5.5,7.7-12.7,22.2-15.7,31.3c-12.5,38.5-4.7,80.3,20.7,111.4l4.3,5.3l-3.4,5.7c-4.7,8-9.8,13.7-15.4,17.7c-2.7,1.9-5.6,3.9-6.4,4.5c-2,1.6-2,4.4-0.1,6.2c1.3,1.4,2,1.4,11.6,1.4c8.5-0.1,11.1-0.3,15.7-1.5c6-1.6,18.3-7.3,22.4-10.4l2.5-1.9l5.3,2.8c29.3,15.5,66,17.7,97,5.9c11.3-4.4,22.6-10.8,31.9-18.3c7.1-5.7,17.2-16.4,22.6-23.9c5.4-7.5,12.7-22,15.7-31.1c9.6-29.2,7.5-60.5-5.8-88.3C212.9,32.5,165.7,5.8,116.8,10.6z M162.6,94.8c7.9,4.9,14.5,9.3,14.6,9.8c0.2,0.5,0,1.3-0.5,1.8c-0.4,0.5-7.2,4.9-15,9.8c-11.4,7.1-14.5,8.7-15.2,8.1c-0.6-0.5-1-2.4-1.1-5.4l-0.2-4.7l-32.4-0.2c-22.6-0.1-32.7-0.4-33.3-0.8c-1.1-1-1.2-14.9-0.1-16.1c0.6-0.6,8.9-0.8,33.5-0.8h32.7v-4.7c0-4.9,0.2-5.7,1.8-5.7C147.8,85.9,154.6,89.9,162.6,94.8z M110.3,137.4l0.2,5.1h32.6c24.6,0,32.9,0.2,33.5,0.8c1.1,1.1,1.1,14.9,0,16c-0.6,0.6-8.9,0.8-33.5,0.8h-32.6l-0.2,5c-0.2,4.5-0.3,5.1-1.5,5.2c-2,0.3-29.8-17.4-30.1-19c-0.1-1,2.6-3.1,14-10.2c9.5-6,14.7-8.8,15.8-8.8C110,132.4,110.1,132.6,110.3,137.4z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const GeminiIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
            d="M1.40236 8.42465L6.55155 6.55032L8.42465 1.40236C8.4676 1.28443 8.54578 1.18257 8.6486 1.11059C8.75141 1.03861 8.87388 1 8.99938 1C9.12489 1 9.24736 1.03861 9.35017 1.11059C9.45299 1.18257 9.53117 1.28443 9.57411 1.40236L11.4484 6.55155L16.5976 8.42465C16.7156 8.4676 16.8174 8.54578 16.8894 8.6486C16.9614 8.75141 17 8.87388 17 8.99938C17 9.12489 16.9614 9.24736 16.8894 9.35017C16.8174 9.45299 16.7156 9.53117 16.5976 9.57411L11.4472 11.4484L9.57411 16.5976C9.53117 16.7156 9.45299 16.8174 9.35017 16.8894C9.24736 16.9614 9.12489 17 8.99938 17C8.87388 17 8.75141 16.9614 8.6486 16.8894C8.54578 16.8174 8.4676 16.7156 8.42465 16.5976L6.55032 11.4472L1.40236 9.57411C1.28443 9.53117 1.18257 9.45299 1.11059 9.35017C1.03861 9.24736 1 9.12489 1 8.99938C1 8.87388 1.03861 8.75141 1.11059 8.6486C1.18257 8.54578 1.28443 8.4676 1.40236 8.42465Z"
            stroke="#576DDD"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.1006 3.35616L14.3879 2.88758L14.8562 1.60059C14.8669 1.57111 14.8864 1.54564 14.9121 1.52765C14.9379 1.50965 14.9685 1.5 14.9998 1.5C15.0312 1.5 15.0618 1.50965 15.0875 1.52765C15.1132 1.54564 15.1328 1.57111 15.1435 1.60059L15.6121 2.88789L16.8994 3.35616C16.9289 3.3669 16.9544 3.38645 16.9724 3.41215C16.9903 3.43785 17 3.46847 17 3.49985C17 3.53122 16.9903 3.56184 16.9724 3.58754C16.9544 3.61325 16.9289 3.63279 16.8994 3.64353L15.6118 4.11211L15.1435 5.39941C15.1328 5.42889 15.1132 5.45436 15.0875 5.47235C15.0618 5.49035 15.0312 5.5 14.9998 5.5C14.9685 5.5 14.9379 5.49035 14.9121 5.47235C14.8864 5.45436 14.8669 5.42889 14.8562 5.39941L14.3876 4.1118L13.1006 3.64353C13.0711 3.63279 13.0456 3.61325 13.0276 3.58754C13.0097 3.56184 13 3.53122 13 3.49985C13 3.46847 13.0097 3.43785 13.0276 3.41215C13.0456 3.38645 13.0711 3.3669 13.1006 3.35616Z"
            stroke="#576DDD"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const AzureIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
            d="M6.61133 10.3026V4.94479L10.7748 2.54121C13.0816 1.2092 17.2116 4.49458 15.4778 7.20091"
            stroke="#576DDD"
            strokeLinejoin="round"
        />
        <path
            d="M6.61133 7.69746L11.251 5.01855L15.4145 7.42213C17.7214 8.75414 16.94 13.9743 13.4686 13.9743"
            stroke="#576DDD"
            strokeLinejoin="round"
        />
        <path
            d="M8.86484 6.39551L13.5046 9.07442V13.8853C13.5046 16.5493 8.59322 18.4841 6.85938 15.4777"
            stroke="#576DDD"
            strokeLinejoin="round"
        />
        <path
            d="M11.1202 7.79492V13.056L6.95668 15.4596C4.64985 16.7916 0.519863 13.5062 2.25371 10.4999"
            stroke="#576DDD"
            strokeLinejoin="round"
        />
        <path
            d="M11.12 10.3022L6.48023 12.9811L2.31676 10.5776C0.00620354 9.24182 0.787552 4.02539 4.25897 4.02539"
            stroke="#576DDD"
            strokeLinejoin="round"
        />
        <path
            d="M8.86433 11.6045L4.22461 8.92559V4.11471C4.22461 1.45069 9.13594 -0.484078 10.8698 2.52225"
            stroke="#576DDD"
            strokeLinejoin="round"
        />
    </svg>
);

const ChevronIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9023 4.90256L7.99997 11.8049L1.0976 4.90256L1.56901 4.43115L7.99997 10.8621L14.4309 4.43115L14.9023 4.90256Z"
            fill="#576DDD"
        />
    </svg>
);

const ResetIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.61992 5.83H6.07992V6.83H1.91992V2.67H2.91992V5.02C3.94992 3.21 5.89992 2 8.11992 2C11.1999 2 13.7499 4.33 14.0799 7.33H13.0699C12.7499 4.89 10.6499 3 8.11992 3C6.13992 3 4.41992 4.15 3.61992 5.83ZM12.3799 10.1699H9.87992V9.16992H14.0499V13.3299H13.0499V11.0299C12.0099 12.8099 10.0799 13.9999 7.87992 13.9999C4.79992 13.9999 2.24992 11.6699 1.91992 8.66992H2.92992C3.24992 11.1099 5.34992 12.9999 7.87992 12.9999C9.85992 12.9999 11.5799 11.8499 12.3799 10.1699Z"
            fill="#576DDD"
        />
    </svg>
);

interface ModelNameDropdownProps {
    onRegenerateClick?: () => void;
    isInfo?: boolean;
    messageModelName?: string;
}

const ModelNameDropdown: React.FC<ModelNameDropdownProps> = ({
    onRegenerateClick,
    isInfo = false,
    messageModelName,
}) => {
    const dispatch = useAppDispatch();
    const { modelName } = useAppSelector((state: RootState) => state.conversations);
    const [pendingModelName, setPendingModelName] = useState<string | null>(null);
    const styles = useStyles();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 744px)');

    const handleItemClick = (name: string): void => {
        setPendingModelName(name);
        dispatch(setModelName(name as MODEL_NAME));
    };

    useEffect(() => {
        if (pendingModelName) {
            onRegenerateClick !== undefined && onRegenerateClick();
            setPendingModelName(null);
        }
    }, [modelName, pendingModelName]);

    if (isInfo) {
        return (
            <div className="svg-container no-hover">
                {
                    //eslint-disable-next-line
                    messageModelName === MODEL_NAME.GEMINI_PRO ? (
                        <div style={{ display: 'flex', alignItems: 'center', height: '16px' }}>
                            <GeminiIcon />
                            <span
                                style={{
                                    marginLeft: '5px',
                                }}
                            >
                                Generated with Gemini 1.5 Pro
                            </span>
                        </div>
                    ) : //eslint-disable-next-line
                    messageModelName === MODEL_NAME.AZURE_TURBO ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '16px',
                                width: 'fit-content',
                            }}
                        >
                            <AzureIcon />
                            <span
                                style={{
                                    marginLeft: '5px',
                                }}
                            >
                                Generated with GPT 4o
                            </span>
                        </div>
                    ) : (
                        ''
                    )
                }
            </div>
        );
    }

    return (
        <div
            className={styles.dropdownContainer}
            style={{ marginLeft: '10px' }}
            onClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}
        >
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <div className="svg-container">
                        {
                            //eslint-disable-next-line
                            modelName === MODEL_NAME.GEMINI_PRO ? (
                                <div style={{ display: 'flex', alignItems: 'center', height: '16px' }}>
                                    <GeminiIcon />
                                    <span
                                        style={{
                                            opacity: 1,
                                            overflow: 'hidden',
                                            display: 'inline-flex',
                                            visibility: 'visible',
                                            transition: 'opacity 0.2s ease, visibility 0.2s ease',
                                            marginLeft: isMobile ? '5px' : '8px',
                                            gap: isMobile ? '' : '8px',
                                        }}
                                    >
                                        <span>REWRITE</span>
                                        <span
                                            style={{
                                                transform: isMenuOpen
                                                    ? 'rotate(180deg) translateY(3px)'
                                                    : 'translateY(2px)',
                                            }}
                                        >
                                            <ChevronIcon />
                                        </span>
                                    </span>
                                </div>
                            ) : //eslint-disable-next-line
                            modelName === MODEL_NAME.AZURE_TURBO ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '16px',
                                        width: 'fit-content',
                                    }}
                                >
                                    <AzureIcon />
                                    <span
                                        style={{
                                            opacity: 1,
                                            overflow: 'hidden',
                                            display: 'inline-flex',
                                            visibility: 'visible',
                                            transition: 'opacity 0.2s ease, visibility 0.2s ease',
                                            marginLeft: isMobile ? '5px' : '8px',
                                            gap: isMobile ? '' : '8px',
                                        }}
                                    >
                                        <span>REWRITE</span>
                                        <span
                                            style={{
                                                transform: isMenuOpen
                                                    ? 'rotate(180deg) translateY(3px)'
                                                    : 'translateY(2px)',
                                            }}
                                        >
                                            <ChevronIcon />
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <ChatChaneIcon />
                            )
                        }
                    </div>
                </MenuTrigger>
                <MenuPopover style={{ borderRadius: '8px' }}>
                    <MenuList>
                        {modelNames.map((name) => (
                            <MenuItem
                                key={name}
                                onClick={() => {
                                    handleItemClick(name);
                                }}
                                className={styles.dropdownItem}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {name === MODEL_NAME.GEMINI_PRO && (
                                        <div style={{ display: 'flex', marginRight: '5px' }}>
                                            {(modelName as MODEL_NAME) === name ? <ResetIcon /> : <GeminiIcon />}
                                        </div>
                                    )}
                                    {name === MODEL_NAME.AZURE_TURBO && (
                                        <div style={{ display: 'flex', marginRight: '5px' }}>
                                            {(modelName as MODEL_NAME) === name ? <ResetIcon /> : <AzureIcon />}
                                        </div>
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className={styles.dropdownItemLabel}>
                                            {(modelName as MODEL_NAME) === name
                                                ? `Try again with ${modelLabels[name as MODEL_NAME]}`
                                                : `Switch to ${modelLabels[name as MODEL_NAME]}`}
                                        </span>
                                        <span className={styles.dropdownItemSubLabel}>
                                            {modelSubLabels[name as MODEL_NAME]}
                                        </span>
                                    </div>
                                    {/*{name === (modelName as MODEL_NAME) && (*/}
                                    {/*    <div style={{ display: 'flex', marginLeft: '5px' }}>*/}
                                    {/*        <Checkmark20 />*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                            </MenuItem>
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    );
};

export default ModelNameDropdown;
