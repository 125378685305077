import { makeStyles } from '@fluentui/react-components';
import React from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { TypingIndicator } from './typing-indicator/TypingIndicator';
import Slide from '@mui/material/Slide';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
    },
});

export const ImagesStatus: React.FC = () => {
    const classes = useClasses();
    const { botResponseStatus } = useAppSelector((state: RootState) => state.images.conversation);

    return (
        <Slide direction="up" in={Boolean(botResponseStatus)} mountOnEnter unmountOnExit>
            <div className={classes.root}>
                <label>{botResponseStatus}</label>
                <TypingIndicator />
            </div>
        </Slide>
    );
};
