import React, { JSX, useEffect, useState } from 'react';
import { makeStyles, shorthands, tokens, Button, mergeClasses } from '@fluentui/react-components';
import {
    MenuItem,
    ListItemText,
    Checkbox,
    TextField,
    Menu,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
} from '@mui/material';
import { Breakpoints } from '../../../styles';
import { useChat } from '../../../libs/hooks';
import { IPrompts, setPrompts } from '../../../redux/features/prompts/promptsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { setPromptSelectedValue, setSelectedTab } from '../../../redux/features/app/appSlice';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import ThirdPopover from '../../ThirdPopover';
import FourthPopover from '../../FourthPopover';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/app/store';
import FifthPopover from '../../FifthPopover';
import SixthPopover from '../../SixthPopover';
import SeventhPopover from '../../SeventhPopover';
import rehypeExternalLinks from 'rehype-external-links';

const IconClose = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6465 13.3536L2.64648 3.35359L3.35359 2.64648L13.3536 12.6465L12.6465 13.3536Z"
            fill="#030F2B"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3536 3.35352L3.35359 13.3535L2.64648 12.6464L12.6465 2.64641L13.3536 3.35352Z"
            fill="#030F2B"
        />
    </svg>
);

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M12.0208 11.0775L14.4046 13.4613C14.6649 13.7216 14.6649 14.1437 14.4046 14.4041C14.1442 14.6644 13.7221 14.6644 13.4618 14.4041L11.078 12.0203C10.0514 12.8417 8.7495 13.333 7.3335 13.333C4.0215 13.333 1.3335 10.645 1.3335 7.33301C1.3335 4.02101 4.0215 1.33301 7.3335 1.33301C10.6455 1.33301 13.3335 4.02101 13.3335 7.33301C13.3335 8.74901 12.8422 10.0509 12.0208 11.0775ZM10.6833 10.5829C11.4985 9.74274 12.0002 8.59674 12.0002 7.33301C12.0002 4.75467 9.91183 2.66634 7.3335 2.66634C4.75516 2.66634 2.66683 4.75467 2.66683 7.33301C2.66683 9.91134 4.75516 11.9997 7.3335 11.9997C8.59723 11.9997 9.74323 11.498 10.5834 10.6828L10.6833 10.5829Z"
            fill="white"
        />
    </svg>
);

const FilterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99967 4.66634C9.99967 5.03453 9.7012 5.33301 9.33301 5.33301C8.96482 5.33301 8.66634 5.03453 8.66634 4.66634C8.66634 4.29815 8.96482 3.99967 9.33301 3.99967C9.7012 3.99967 9.99967 4.29815 9.99967 4.66634ZM10.6243 4.99967H14.6663V4.33301L10.6243 4.33301C10.4763 3.75793 9.95429 3.33301 9.33301 3.33301C8.71173 3.33301 8.18969 3.75793 8.04168 4.33301H1.33301V4.99967H8.04168C8.18969 5.57475 8.71173 5.99967 9.33301 5.99967C9.95429 5.99967 10.4763 5.57475 10.6243 4.99967ZM3.37501 8.33301H1.33301V7.66634H3.37501C3.52303 7.09127 4.04506 6.66634 4.66634 6.66634C5.28762 6.66634 5.80965 7.09127 5.95767 7.66634L14.6663 7.66634V8.33301L5.95767 8.33301C5.80965 8.90808 5.28762 9.33301 4.66634 9.33301C4.04506 9.33301 3.52303 8.90808 3.37501 8.33301ZM5.33301 7.99967C5.33301 7.63148 5.03453 7.33301 4.66634 7.33301C4.29815 7.33301 3.99967 7.63148 3.99967 7.99967C3.99967 8.36786 4.29815 8.66634 4.66634 8.66634C5.03453 8.66634 5.33301 8.36786 5.33301 7.99967ZM10.6663 9.99967C10.0451 9.99967 9.52303 10.4246 9.37501 10.9997H1.33301V11.6663H9.37501C9.52303 12.2414 10.0451 12.6663 10.6663 12.6663C11.2876 12.6663 11.8097 12.2414 11.9577 11.6663H14.6663V10.9997H11.9577C11.8097 10.4246 11.2876 9.99967 10.6663 9.99967ZM10.6663 10.6663C11.0345 10.6663 11.333 10.9648 11.333 11.333C11.333 11.7012 11.0345 11.9997 10.6663 11.9997C10.2982 11.9997 9.99967 11.7012 9.99967 11.333C9.99967 10.9648 10.2982 10.6663 10.6663 10.6663Z"
            fill="#1A1D20"
        />
    </svg>
);

const ArrowDropUpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.09766 11.0979L8.00002 4.19556L14.9024 11.0979L14.431 11.5693L8.00002 5.13837L1.56906 11.5693L1.09766 11.0979Z"
            fill="#1A1D20"
        />
    </svg>
);

const ArrowDropDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9023 4.90207L7.99997 11.8044L1.0976 4.90207L1.56901 4.43066L7.99997 10.8616L14.4309 4.43066L14.9023 4.90207Z"
            fill="#1A1D20"
        />
    </svg>
);

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 12.7071L21.6465 22.3536L22.3536 21.6465L12.7071 12L22.3536 2.35359L21.6465 1.64648L12 11.2929L2.35359 1.64648L1.64648 2.35359L11.2929 12L1.64648 21.6465L2.35359 22.3536L12 12.7071Z"
            fill="#030F2B"
        />
    </svg>
);

const ChevronLeftIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0979 14.9023L4.19556 7.99997L11.0979 1.09761L11.5693 1.56901L5.13837 7.99997L11.5693 14.4309L11.0979 14.9023Z"
            fill="#576DDD"
        />
    </svg>
);

const CssTextField = styled(TextField)({
    width: '100%',
    '& label': {
        color: '#54616D',
        top: '-3px',
    },
    '& label.Mui-focused': {
        color: '#1D1D1D',
        top: '0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#8F8A80',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '24px',
        height: '100%',
        '& fieldset': {
            borderColor: '#8F8A80',
        },
        '&:hover fieldset': {
            borderColor: '#8F8A80',
        },
        '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: '#8F8A80',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '12.5px 14px',
    },
});

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow('auto'),
        ...shorthands.padding(tokens.spacingVerticalM),
        marginLeft: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        display: 'flex',
        justifyContent: 'center',
        ...Breakpoints.small({
            marginLeft: '15px',
            paddingRight: '15px',
            marginTop: '80px',
        }),
    },
    wrapper: {
        ...shorthands.gap(tokens.spacingVerticalM),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifySelf: 'center',
    },
    sectionTitle: {
        ...shorthands.margin(0),
        marginBottom: '16px',
        fontSize: '32px',
        fontWeight: '400',
        ...Breakpoints.small({
            marginTop: '0',
            marginBottom: '0',
        }),
    },
    controls: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalS),
        marginBottom: '20px',
    },
    areaField: {
        display: 'flex',
        ...shorthands.gap('16px'),
        ...Breakpoints.small({
            flexDirection: 'column',
        }),
    },
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap('16px'),
        paddingBottom: '80px',
    },
    gridItem: {
        ...shorthands.overflow('hidden'),
        ...shorthands.flex(0, 0, 'calc(33.33% - 16px)'),
        ...shorthands.padding('16px'),
        ...shorthands.border(`1px solid ${tokens.colorNeutralStroke1}`),
        ...shorthands.borderRadius('16px'),
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        ...Breakpoints.laptop({
            ...shorthands.flex(0, 0, 'calc(50% - 16px)'),
        }),
        ...Breakpoints.small({
            ...shorthands.flex(0, 0, 'calc(100%)'),
        }),
    },
    spinnerContainer: {
        textAlign: 'center',
        ...shorthands.padding('20px'),
    },
    btns: {
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.gap('24px'),
    },
    btn: {
        textTransform: 'uppercase',
        fontSize: '14px',
        ...shorthands.borderRadius('20px'),
        ...shorthands.padding('4px', '16px'),
        fontWeight: '400',
        ...shorthands.border('1px', 'solid', '#576DDD'),
        color: '#fff',
        backgroundColor: '#576DDD',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#576DDD',
            ...shorthands.borderColor('#576DDD'),
            '& svg': {
                filter: 'invert(1)',
            },
        },
        '&:hover:active': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
        },
        '&[data-fui-focus-visible]': {
            ...shorthands.borderRadius('20px'),
            ...shorthands.borderColor('#9CB0EC'),
        },
        '& span': {
            marginLeft: '5px',
        },
    },
    btnPrimary: {
        backgroundColor: '#576DDD',
        color: '#ffffff',
    },
    textarea: {
        ...shorthands.padding('12px', '170px', '12px', '12px'),
        ...shorthands.borderRadius('4px', '4px', '4px', '4px'),
        ...shorthands.border('1px', 'solid', '#1A1D20'),
        '&:focus': {
            ...shorthands.borderColor('#9CB0EC'),
            ...shorthands.outline('0'),
        },
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        position: 'absolute',
        right: '0',
        bottom: '9px',
        zIndex: '100',
    },
    content: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        width: '100%',
        '& .fui-Textarea:after': {
            display: 'none',
        },
        '& .fui-Textarea': {
            ...shorthands.border('0 !important'),
            paddingBottom: '0',
            ...shorthands.outline('0'),
        },
        '& .fui-Textarea:hover': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
        '& .fui-Textarea:focus': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
        ...Breakpoints.small({
            flexDirection: 'column',
        }),
    },
    btnSearch: {
        backgroundColor: '#54616D',
        ...shorthands.border('0'),
        ...shorthands.borderRadius('50%'),
        maxWidth: '32px',
        minWidth: '32px',
        width: '32px',
        maxHeight: '32px',
        minHeight: '32px',
        height: '32px',
        marginRight: '7px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#54616D',
            opacity: '0.7',
            '&:active': {
                backgroundColor: '#54616D',
                opacity: '0.7',
            },
        },
        '&:active': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
        },
    },
    mobileDisplayingNone: {
        ...Breakpoints.small({
            display: 'none',
        }),
    },
    mobileDisplaying: {
        ...Breakpoints.small({
            display: 'flex!important',
        }),
    },
    mobileFilterArea: {
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }),
    },
    dialogRoot: {
        zIndex: '9999!important',
    },
    dialogPaper: {
        position: 'absolute!important' as 'absolute',
        right: 0,
        top: 0,
        ...shorthands.margin('0!important'),
        height: '100%',
        maxHeight: '100%!important',
        maxWidth: '100%!important',
        borderBottomLeftRadius: '0!important',
        borderTopLeftRadius: '24px!important',
        borderBottomRightRadius: '0!important',
        borderTopRightRadius: '24px!important',
        backgroundColor: '#F7F3ED!important',
        width: '100%!important',
        ...Breakpoints.small({
            top: '100px',
            height: 'calc(100% - 100px)',
        }),
    },
    dialogActions: {
        ...shorthands.borderTop('1px solid #E0E5F0'),
        flexDirection: 'column',
        ...shorthands.padding('20px', '24px'),
        ...Breakpoints.small({
            flexDirection: 'row',
            ...shorthands.gap('8px'),
        }),
    },
    filterBtn: {
        textTransform: 'uppercase',
        ...shorthands.borderRadius('32px'),
        ...shorthands.border('1px', 'solid', '#9CB0EC'),
        ...shorthands.padding('16px', '24px'),
        width: '100%',
        color: '#576DDD',
        fontWeight: '400',
        fontSize: '14px',
        ...shorthands.gap('5px'),
        backgroundColor: '#FFFFFF',
        ...shorthands.margin('5px', '0'),
        marginLeft: '0!important',
        '&:hover': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
        '&:hover:active': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
        ...Breakpoints.small({
            ...shorthands.padding('4px', '6px'),
        }),
    },
    filterBtnPrimary: {
        backgroundColor: '#576DDD',
        color: '#ffffff',
    },
    filterQuantity: {
        display: 'inline-block',
        backgroundColor: '#576DDD',
        ...shorthands.borderRadius('32px'),
        ...shorthands.padding('8px', '16px'),
        position: 'relative',
        marginLeft: '10px',
        fontSize: '14px',
        color: '#fff',
    },
    retour: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        marginTop: '32px',
        color: '#576DDD',
        cursor: 'pointer',
        ...Breakpoints.small({
            marginBottom: '5px',
            marginTop: '5px',
        }),
    },
});

export const PromptsTab: React.FC = () => {
    const classes = useStyles();
    const chat = useChat();

    const allPrompts = useAppSelector((state) => state.prompts.promptList.prompts);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [loadingById, setLoadingById] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenFilterDialog, setIsOpenFilterDialog] = useState(false);
    const [isOpenInfoDialog, setIsOpenInfoDialog] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<IPrompts | null>(null);
    const { popovers } = useSelector((state: RootState) => state.popover);
    const shadowClassName2 = popovers[2] ? 'shadow-zindex popover1' : '';
    const shadowClassName3 = popovers[3] ? 'shadow-zindex popover1' : '';
    const shadowClassName4 = popovers[4] ? 'shadow-zindex popover1' : '';
    const shadowClassName5 = popovers[5] ? 'shadow-zindex popover1' : '';
    const shadowClassName6 = popovers[6] ? 'shadow-zindex popover1' : '';

    const handleModal = () => {
        setIsOpenModal(!isOpenModal);
    };

    const handleFilterDialog = () => {
        setIsOpenFilterDialog(!isOpenFilterDialog);
    };

    const handleInfoDialog = () => {
        setIsOpenInfoDialog(!isOpenInfoDialog);
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleTagToggle = (tag: string) => {
        setSelectedTags((prevSelected) => {
            if (prevSelected.includes(tag)) {
                return prevSelected.filter((selectedTag) => selectedTag !== tag);
            } else {
                return [...prevSelected, tag];
            }
        });
    };

    const renderArrow = (): JSX.Element => {
        return isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    };

    const [filteredPrompts, setFilteredPrompts] = useState(allPrompts);

    const fetchPrompts = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await chat.fetchPrompts();
            dispatch(setPrompts(response));
        } catch (err) {
            setError('Failed to fetch prompts. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchPromptById = async (id: number) => {
        setLoadingById(true);
        setError(null);
        try {
            const response = await chat.fetchPromptById(id);
            setSelectedPrompt(response);
        } catch (err) {
            setError('Failed to fetch prompt details. Please try again.');
            console.error(err);
        } finally {
            setLoadingById(false);
        }
    };

    useEffect(() => {
        void fetchPrompts();
    }, []);

    useEffect(() => {
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        const filtered = allPrompts.filter((prompt) => {
            const matchesSearch =
                prompt.title?.toLowerCase().includes(lowerCaseSearchTerm) ||
                prompt.value?.toLowerCase().includes(lowerCaseSearchTerm) ||
                prompt.description?.toLowerCase().includes(lowerCaseSearchTerm);
            const matchesTags = selectedTags.length === 0 || selectedTags.some((tag) => prompt.tags.includes(tag));
            return matchesSearch && matchesTags;
        });
        setFilteredPrompts(filtered);
    }, [searchTerm, selectedTags, allPrompts]);

    const uniqueTags = [...new Set(allPrompts.flatMap((prompt) => prompt.tags))];

    const removeTag = (tagToRemove: string) => {
        setSelectedTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
    };

    const handleUsePrompt = (prompt: string) => {
        dispatch(setSelectedConversation(''));
        dispatch(setSelectedTab('chat'));
        setTimeout(() => {
            dispatch(setPromptSelectedValue(prompt));
        }, 500);
    };

    const preprocessText = (text: string, isDisplayShadow?: boolean): string => {
        const regex = /\[(.*?)\]/g;
        let result = '';
        let lastIndex = 0;

        text.replace(regex, (match: string, _: string, offset: number): string => {
            result += text.slice(lastIndex, offset);
            result += `<span style="background-color: #FBEDAD;" class="${isDisplayShadow ? shadowClassName5 : ''}" id="${isDisplayShadow ? 'sixth-anchor' : ''}">${match}</span>`;
            lastIndex = offset + match.length;
            return match;
        });

        result += text.slice(lastIndex);

        return result;
    };

    return (
        <div className={classes.root}>
            <ThirdPopover />
            <FourthPopover />
            {isOpenInfoDialog && (
                <Dialog
                    open={isOpenInfoDialog}
                    onClose={handleInfoDialog}
                    fullWidth
                    classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }}
                    BackdropProps={{
                        style: {
                            backgroundColor: '#030F2B99',
                            backdropFilter: 'blur(8px)',
                        },
                    }}
                >
                    <DialogTitle style={{ display: 'flex' }}>
                        <div>Details</div>
                    </DialogTitle>
                    <div
                        onClick={handleInfoDialog}
                        style={{
                            textDecoration: 'underline',
                            textUnderlineOffset: '7px',
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 30,
                            top: 25,
                            color: '#000',
                        }}
                    >
                        <IconClose />
                    </div>
                    <DialogContent>
                        <>
                            {loadingById && (
                                <div className={classes.spinnerContainer}>
                                    <CircularProgress />
                                    <p>Loading prompt...</p>
                                </div>
                            )}
                        </>
                        <>
                            {!loadingById && selectedPrompt && (
                                <>
                                    <p
                                        style={{
                                            fontSize: '28px',
                                            fontWeight: '700',
                                            marginBottom: '8px',
                                            marginTop: '0',
                                        }}
                                    >
                                        {selectedPrompt.title}
                                    </p>
                                    <div style={{ marginBottom: '24px' }}>
                                        {selectedPrompt.tags.map((tag, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    padding: '1px 5px',
                                                    textTransform: 'uppercase',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    border: '1px solid #8D93AB',
                                                    background: '#E0E5F0',
                                                    marginRight: '5px',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                    <div
                                        style={{
                                            background: '#fff',
                                            padding: '16px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRadius: '16px',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <p style={{ margin: '0', fontSize: '24px', fontWeight: '700' }}>Prompt</p>
                                        <p dangerouslySetInnerHTML={{ __html: preprocessText(selectedPrompt.value) }} />
                                    </div>
                                    <div
                                        style={{
                                            background: '#fff',
                                            padding: '16px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRadius: '16px',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <p style={{ margin: '0', fontSize: '24px', fontWeight: '700' }}>Example</p>
                                        <div>
                                            <ReactMarkdown
                                                remarkPlugins={[remarkGfm]}
                                                rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
                                                transformLinkUri={(value: string) => value}
                                            >
                                                {selectedPrompt.description}
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            className={classes.filterBtn}
                            onClick={() => {
                                handleInfoDialog();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={mergeClasses(
                                classes.filterBtn,
                                classes.filterBtnPrimary,
                                'gtmPromptStudioUsePrompt',
                            )}
                            onClick={() => {
                                if (!selectedPrompt) return;
                                handleUsePrompt(selectedPrompt.value);
                            }}
                        >
                            Use
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {isOpenFilterDialog && (
                <Dialog
                    open={isOpenFilterDialog}
                    onClose={handleFilterDialog}
                    fullWidth
                    classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }}
                    BackdropProps={{
                        style: {
                            backgroundColor: '#030F2B99',
                            backdropFilter: 'blur(8px)',
                        },
                    }}
                >
                    <DialogTitle style={{ display: 'flex' }}>
                        <div>Scope</div>
                        <div className={classes.filterQuantity}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>{selectedTags.length}</span>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        filter: 'invert(1)',
                                        marginLeft: '4px',
                                    }}
                                    onClick={() => {
                                        setSelectedTags([]);
                                    }}
                                >
                                    <IconClose />
                                </div>
                            </div>
                        </div>
                    </DialogTitle>
                    <div
                        onClick={handleFilterDialog}
                        style={{
                            textDecoration: 'underline',
                            textUnderlineOffset: '7px',
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 30,
                            top: 25,
                            color: '#000',
                        }}
                    >
                        <IconClose />
                    </div>
                    <DialogContent>
                        {uniqueTags.map((tag) => (
                            <div
                                style={{ padding: '0 5px', marginBottom: '10px', display: 'flex' }}
                                key={tag}
                                onClick={() => {
                                    handleTagToggle(tag);
                                }}
                            >
                                <Checkbox
                                    style={{ padding: '0', marginRight: '5px', color: '#000' }}
                                    checked={selectedTags.includes(tag)}
                                />
                                <ListItemText primary={tag} />
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            className={classes.filterBtn}
                            onClick={() => {
                                handleFilterDialog();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={mergeClasses(classes.filterBtn, classes.filterBtnPrimary)}
                            onClick={() => {
                                handleFilterDialog();
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {isOpenModal && (
                <Dialog
                    PaperProps={{
                        style: {
                            backgroundColor: 'rgb(247, 243, 237)',
                            borderRadius: '24px',
                            padding: '24px 48px',
                            maxWidth: '60%',
                        },
                    }}
                    BackdropProps={{
                        style: {
                            backgroundColor: '#030F2B99',
                            backdropFilter: 'blur(8px)',
                        },
                    }}
                    fullWidth={true}
                    maxWidth={'xl'}
                    open={isOpenModal}
                    onClose={handleModal}
                >
                    <SixthPopover />
                    {loadingById && (
                        <div className={classes.spinnerContainer}>
                            <CircularProgress />
                            <p>Loading prompt...</p>
                        </div>
                    )}
                    {!loadingById && selectedPrompt && (
                        <>
                            <p style={{ fontSize: '32px', marginBottom: '32px', marginTop: '0' }}>Details</p>
                            <p
                                style={{
                                    fontSize: '28px',
                                    fontWeight: '700',
                                    marginBottom: '8px',
                                    marginTop: '0',
                                }}
                            >
                                {selectedPrompt.title}
                            </p>
                            <div style={{ marginBottom: '24px' }}>
                                {selectedPrompt.tags.map((tag, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            padding: '1px 5px',
                                            textTransform: 'uppercase',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            border: '1px solid #8D93AB',
                                            background: '#E0E5F0',
                                            marginRight: '5px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>
                            <div
                                style={{
                                    background: '#fff',
                                    padding: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '16px',
                                    marginBottom: '8px',
                                }}
                            >
                                <p style={{ margin: '0', fontSize: '24px', fontWeight: '700' }}>Prompt</p>
                                <p dangerouslySetInnerHTML={{ __html: preprocessText(selectedPrompt.value, true) }} />
                            </div>
                            <div
                                style={{
                                    background: '#fff',
                                    padding: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '16px',
                                    marginBottom: '24px',
                                }}
                            >
                                <p style={{ margin: '0', fontSize: '24px', fontWeight: '700' }}>Example</p>
                                <div>
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
                                        transformLinkUri={(value: string) => value}
                                    >
                                        {selectedPrompt.description}
                                    </ReactMarkdown>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '30px',
                                    right: '45px',
                                }}
                                onClick={() => {
                                    handleModal();
                                }}
                            >
                                <CloseIcon />
                            </div>
                            <div
                                className={classes.btns}
                                style={{
                                    flex: '0 1 auto',
                                    justifyContent: 'end',
                                }}
                            >
                                <SeventhPopover />
                                <Button
                                    className={classes.btn}
                                    size="small"
                                    onClick={() => {
                                        handleModal();
                                    }}
                                    appearance="secondary"
                                >
                                    Cancel
                                </Button>
                                <div id="seventh-anchor" className={shadowClassName6}>
                                    <Button
                                        className={mergeClasses(
                                            classes.btn,
                                            classes.btnPrimary,
                                            'gtmPromptStudioUsePrompt',
                                        )}
                                        size="small"
                                        onClick={() => {
                                            handleUsePrompt(selectedPrompt.value);
                                        }}
                                        appearance="primary"
                                    >
                                        Use
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </Dialog>
            )}
            <div className={classes.wrapper}>
                <div
                    className={classes.retour}
                    onClick={() => {
                        dispatch(setSelectedTab('chat'));
                    }}
                >
                    <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                        <ChevronLeftIcon />
                    </div>
                    <p>BACK</p>
                </div>
                <p className={classes.sectionTitle}>Prompt Studio</p>
                <div className={classes.controls}>
                    <div className={classes.areaField}>
                        <div className={`${shadowClassName2} ${classes.content}`} id="third-anchor">
                            <CssTextField
                                label="Search"
                                title="Search"
                                placeholder="Search prompts by title and example"
                                aria-label="Search input field. Click enter to submit input."
                                id="search-input"
                                variant="outlined"
                                className={classes.textarea}
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                            />
                            <div className={classes.essentials}>
                                <button className={mergeClasses(classes.btnSearch, 'gtmPromptStudioSearchPrompt')}>
                                    <SearchIcon />
                                </button>
                            </div>
                        </div>
                        <div className={classes.mobileFilterArea}>
                            <span style={{ color: '#656B80', fontWeight: '700' }}>
                                {filteredPrompts.length} results
                            </span>
                            <Button
                                style={{
                                    minWidth: 'auto',
                                    padding: '13px',
                                    borderRadius: '50%',
                                    gap: '4px',
                                    background: 'transparent',
                                    border: '1px solid #656B80',
                                }}
                                onClick={handleFilterDialog}
                                aria-haspopup="true"
                                aria-expanded={isOpen ? 'true' : 'false'}
                            >
                                <FilterIcon />
                            </Button>
                        </div>
                        <div className={`${shadowClassName3} ${classes.mobileDisplayingNone}`} id="fourth-anchor">
                            <Button
                                style={{
                                    padding: '13px 20px',
                                    borderRadius: '32px',
                                    gap: '4px',
                                    background: 'transparent',
                                    border: '1px solid #656B80',
                                }}
                                onClick={handleButtonClick}
                                aria-haspopup="true"
                                aria-expanded={isOpen ? 'true' : 'false'}
                            >
                                <FilterIcon /> Filter {renderArrow()}
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={isOpen}
                                onClose={handleClose}
                                PaperProps={{
                                    sx: { borderRadius: '8px', marginTop: '8px' },
                                }}
                                MenuListProps={{
                                    role: 'listbox',
                                    sx: {
                                        paddingBottom: '0',
                                    },
                                }}
                            >
                                {uniqueTags.map((tag) => (
                                    <MenuItem
                                        style={{ padding: '0 8px', marginBottom: '10px' }}
                                        key={tag}
                                        className="gtmPromptStudioFilter"
                                        onClick={() => {
                                            handleTagToggle(tag);
                                        }}
                                    >
                                        <Checkbox
                                            className="gtmPromptStudioFilter"
                                            style={{ padding: '0', marginRight: '5px', color: '#000' }}
                                            checked={selectedTags.includes(tag)}
                                        />
                                        <ListItemText className="gtmPromptStudioFilter" primary={tag} />
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </div>
                    <div className={classes.mobileDisplayingNone} style={{ marginBottom: '10px' }}>
                        {selectedTags.map((t, index) => (
                            <span
                                key={index}
                                style={{
                                    padding: '1px 5px',
                                    textTransform: 'uppercase',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    border: '1px solid #8D93AB',
                                    background: '#E0E5F0',
                                    marginRight: '5px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    removeTag(t);
                                }}
                            >
                                <span style={{ marginRight: '3px' }}>{t}</span>
                                <IconClose />
                            </span>
                        ))}
                    </div>
                </div>
                {loading && (
                    <div className={classes.spinnerContainer}>
                        <CircularProgress />
                        <p>Loading prompts...</p>
                    </div>
                )}
                {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
                {!loading && filteredPrompts.length === 0 && !error && (
                    <p style={{ textAlign: 'center' }}>No prompts found.</p>
                )}
                {!loading && (
                    <div className={classes.gridContainer}>
                        {filteredPrompts.map((prompt, index) => (
                            <div key={prompt.id} className={classes.gridItem}>
                                <p
                                    style={{
                                        fontWeight: '700',
                                        fontSize: '20px',
                                        color: '#030F2B',
                                        margin: '0',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {prompt.title}
                                </p>
                                <div>
                                    {prompt.tags.map((t, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                padding: '1px 5px',
                                                textTransform: 'uppercase',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                border: '1px solid #8D93AB',
                                                background: '#E0E5F0',
                                                marginRight: '5px',
                                            }}
                                        >
                                            {t}
                                        </span>
                                    ))}
                                </div>
                                {index === 0 && <FifthPopover />}
                                <p
                                    style={{
                                        fontSize: '16px',
                                        color: '#030F2B',
                                        margin: '0',
                                        marginBottom: '10px',
                                        minHeight: '100px',
                                        flex: '1 0 auto',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: preprocessText(prompt.value) }}
                                />
                                <div
                                    className={classes.btns}
                                    style={{
                                        flex: '0 1 auto',
                                    }}
                                >
                                    <span
                                        id={index === 0 ? 'fifth-anchor' : ''}
                                        className={mergeClasses(
                                            `${classes.mobileDisplayingNone} ${index === 0 ? shadowClassName4 : ''}`,
                                            'gtmPromptStudioShowDetails',
                                        )}
                                        style={{
                                            textTransform: 'uppercase',
                                            textDecoration: 'underline',
                                            textUnderlineOffset: '7px',
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',
                                        }}
                                        onClick={() => {
                                            setIsOpenModal(true);
                                            void fetchPromptById(prompt.id);
                                        }}
                                    >
                                        Show details
                                    </span>
                                    <span
                                        className={mergeClasses(classes.mobileDisplaying, 'gtmPromptStudioShowDetails')}
                                        style={{
                                            textTransform: 'uppercase',
                                            textDecoration: 'underline',
                                            textUnderlineOffset: '7px',
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',
                                            display: 'none',
                                        }}
                                        onClick={() => {
                                            handleInfoDialog();
                                            void fetchPromptById(prompt.id);
                                        }}
                                    >
                                        Show details
                                    </span>
                                    <Button
                                        className={mergeClasses(classes.btn, 'gtmPromptStudioUsePrompt')}
                                        size="small"
                                        onClick={() => {
                                            handleUsePrompt(prompt.value);
                                        }}
                                        appearance="secondary"
                                    >
                                        Use
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
