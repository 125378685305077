import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

const ArrowDownwardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g>
            <path d="M25 0H7a7 7 0 0 0-7 7v18a7 7 0 0 0 7 7h18a7 7 0 0 0 7-7V7a7 7 0 0 0-7-7zm5 25a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h18a5 5 0 0 1 5 5z" />
            <path d="M17 23.59V5h-2v18.59l-5.29-5.3-1.42 1.42 7 7a1 1 0 0 0 1.41 0l7-7-1.41-1.41z" />
        </g>
    </svg>
);

const CodeOfUse: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [showArrow, setShowArrow] = useState<boolean>(true);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (window.innerWidth <= 744) return;

        const lastAccepted: string | null = localStorage.getItem('codeOfUseLastAccepted');
        const now = new Date().getTime();
        const twoMonthsInMs = 60 * 24 * 60 * 60 * 1000;

        if (!lastAccepted || now - Number(lastAccepted) > twoMonthsInMs) {
            setOpen(true);
        }
    }, []);

    const handleScroll = () => {
        const contentElement = contentRef.current;
        if (!contentElement) return;

        const isAtBottom = contentElement.scrollHeight - contentElement.scrollTop <= contentElement.clientHeight + 1;

        setShowArrow(!isAtBottom);
    };

    const handleAccept = (): void => {
        const now = new Date().getTime();
        localStorage.setItem('codeOfUseLastAccepted', now.toString());
        setOpen(false);
    };

    const handleArrowClick = (): void => {
        const contentElement = contentRef.current;
        if (!contentElement) return;

        contentElement.scrollTo({
            top: contentElement.scrollHeight,
            behavior: 'smooth',
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => {}}
            onBackdropClick={() => {
                return;
            }}
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth
            BackdropProps={{
                style: {
                    backgroundColor: '#030F2B99',
                    backdropFilter: 'blur(8px)',
                },
            }}
            sx={{ zIndex: 10000 }}
        >
            <DialogContent sx={{ paddingBottom: 0 }}>
                <div
                    className="code-of-use-content"
                    style={{ maxHeight: '500px', overflowY: 'auto' }}
                    ref={contentRef}
                    onScroll={handleScroll}
                >
                    <div style={{ marginBottom: '40px' }}>
                        <div style={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px' }}>
                            <center>MaIA - Code of Use</center>
                        </div>
                        <div>
                            <center>Read me before use - Purpose of this service reminder</center>
                            <p>
                                <strong>Please carefully read the present Code of Use before using MaIA</strong>. MaIA
                                assists employees in their daily activities in many business activities such as document
                                synthesis and content generation (draft emails, translations, etc.). MaIA functions with
                                three pre-trained AI models:
                            </p>
                            <ul>
                                <li>GPT-4o (Microsoft - Azure OpenAI),</li>
                                <li>Gemini 1.5 Pro (Google),</li>
                                <li>Imagen 3 (Google) for text to image.</li>
                            </ul>
                            <p>
                                The purpose of this training is to allow MaIA to understand and generate content such as
                                text and image.
                                <br />
                                <strong>When using MaIA, keep in mind</strong>:
                            </p>
                            <ul>
                                <li>
                                    <strong>MaIA is not meant to serve as a knowledge base</strong>. Using it as one may
                                    result in inaccuracies or biases in the answers delivered. Generated content should
                                    always be reviewed with a challenging eye and validated by experts (e.g. coding
                                    recommendations should be reviewed by a software expert, legal recommendations
                                    should be reviewed by a lawyer, translation to Korean should not be sent directly to
                                    a customer before being reviewed by a Korean speaker, creative content should be
                                    reviewed by an IP lawyer, etc.).
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <strong>To obtain relevant results, provide accurate and sufficient context</strong>{' '}
                                    (prompts and/or documents). Several iterations are necessary to obtain qualitative
                                    results or accurate answers. Prompt training is provided on the Forward platform.
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <strong>Generated content may infringe third parties&rsquo; rights</strong>. In case
                                    of use of MaIA as part of a creative process, the generated content should be
                                    reviewed by your Legal/IP department.
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    Due to the previous points, using generated content outside the company, or for
                                    public relations or marketing purposes, requires a thorough prior review and
                                    validation.
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <strong>
                                        Context - meaning the prompts and content you provide to MaIA in the chat box -
                                        exists only within a single conversation
                                    </strong>
                                    . Documents, previous prompts and answers are providing context to receive a
                                    relevant answer, but not beyond your conversation. Context is not shared between
                                    conversations nor users, hence not between Maisons.
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    Context provided through documents and prompts within a conversation will not be
                                    stored outside of LVMH&apos;s environment nor reused by Microsoft, Google, OpenAI or
                                    other Microsoft, Google and OpenAI customers.
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <strong>
                                        MaIA offers a more secure environment than public LLMs such as Chat -GPT or
                                        other GenAI tools publicly available. Please use MaIA, our own GPT, made by us,
                                        for us
                                    </strong>
                                    .
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    Like any other activity at work, strategic information and personal data are
                                    valuable assets that should be handled with care. Therefore,{' '}
                                    <strong>
                                        use of personal information or strategic data as prompts should be avoided.
                                    </strong>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <strong>This tool relies on rapidly evolving GenAI technologies</strong>. Occasional
                                    maintenance or security related unavailability may occur.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {showArrow && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '10px',
                            width: '24px',
                            height: '34p',
                            animation: 'jump 1s infinite',
                            cursor: 'pointer',
                        }}
                        onClick={handleArrowClick}
                    >
                        <ArrowDownwardIcon />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        margin: 'auto',
                        borderRadius: '28px',
                        background: '#030F2B',
                        color: '#fff',
                        padding: '16px 48px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleAccept}
                    disabled={showArrow}
                    color="primary"
                    variant="contained"
                >
                    I HAVE READ & ACCEPT THE CODE OF USE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CodeOfUse;
